import React, { useCallback, useRef } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as css from "./pro-features.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"
import MetaTags from "../components/MetaTags"
import { Link } from "@gatsbyjs/reach-router"

// markup
const ProFeatures = ({ location }: any) => {

    /**
 * Parameter
 */
    const params = new URLSearchParams(location.search);
    const parameter1 = params.get("slug");
    const [packageSlug, setPackageSlug] = React.useState('public')


    React.useEffect(() => {
        if (parameter1 == 'pro_one_month' || parameter1 == 'pro_six_month') {
            setPackageSlug(parameter1)
        }
    }, [parameter1])

    return (
        <>
            <MetaTags
                metaDescription="Fitur Pro"
                metaTitle="Fitur Pro"
                metaPath="/pro-features"
                metaPreviewImage={metaPreviewImage}
            />
            <Header />

            <Container className={css.section}>
                <Row>
                    <Col>
                        {
                            packageSlug == 'pro_one_month' || packageSlug == 'pro_six_month' ? (
                                <>
                                    <h1>Selamat Datang Pengguna Pro!</h1>

                                    <div className={css.illustration}>
                                        <img src="https://bucket-prod.ktpanabul.com/assets/email/new_pro_user_illustration.png" />
                                    </div>
                                    <p className={css.textCenter}>
                                        Terima kasih telah berlangganan paket Pro! <br />
                                        Dengan ini kamu tidak hanya bisa menikmati semua fitur KTP Anabul namun
                                        juga ikut mendukung misi KTP Anabul yaitu <Link to={'/mission'} >Menemukan Lebih Banyak Hewan Yang Hilang</Link>.
                                        <br /><br />
                                        Berikut beberap fitur keren yang bisa kamu nikmati sekarang.
                                    </p>

                                    <div className={css.line} />

                                    <h2 className={css.textCenter}>Profil Anabul Verified</h2>
                                    <p>
                                        Tampil keren dengan tanda centang biru dibagian profil anabul kamu.
                                    </p>

                                    {
                                        packageSlug == 'pro_one_month' ? (
                                            <h2 className={css.textCenter}>Bisa Tambahkan Hingga 5 KTP!</h2>
                                        ) : (
                                            <h2 className={css.textCenter}>Bisa Tambahkan Hingga 10 KTP!</h2>
                                        )
                                    }

                                    <p>
                                        Daftarkan lebih banyak anabul dalam satu akun, satu keluarga anabul bisa ikut semua kok!
                                    </p>


                                    <h2 className={css.textCenter}>Akses Semua Tema</h2>
                                    <p>
                                        Kamu bisa mengakses semua tema KTP yang ada, tanpa perlu repot melakukan pembayaran setiap kali mengunduh.
                                    </p>


                                    {
                                        packageSlug == 'pro_six_month' ? (
                                            <>
                                                <h2 className={css.textCenter}>Tema Custom</h2>
                                                <p>
                                                    Kamu punya kesempatan untuk meminta 1 tema custom sesuai keinginanmu.
                                                </p>
                                            </>
                                        ) : null
                                    }

                                    <h2 className={css.textCenter}>Fitur yang akan datang...</h2>
                                    <p>
                                        Akun PRO akan mendapat fitur-fitur terbaru yang nantinya akan rilis.
                                    </p>


                                </>
                            ) : (
                                <>
                                    <h1>Fitur Pengguna Pro!</h1>

                                    <div className={css.illustration}>
                                        <img src="https://bucket-prod.ktpanabul.com/assets/email/new_pro_user_illustration.png" />
                                    </div>
                                    <p className={css.textCenter}>
                                        Berikut beberap fitur keren yang bisa dinikmati ketika meningkatkan ke akun Pro.
                                    </p>

                                    <div className={css.line} />

                                    <h2 className={css.textCenter}>Profil Anabul Verified</h2>
                                    <p>
                                        Tampil keren dengan tanda centang biru dibagian profil anabul.
                                    </p>

                                    <h2 className={css.textCenter}>Bisa Tambahkan Hingga 10 KTP!</h2>
                                    <p>
                                        Daftarkan lebih banyak anabul dalam satu akun, satu keluarga anabul bisa ikut semua kok!
                                    </p>


                                    <h2 className={css.textCenter}>Akses Semua Tema</h2>
                                    <p>
                                        Akun pro bisa mengakses semua tema KTP yang ada, tanpa perlu repot melakukan pembayaran setiap kali mengunduh.
                                    </p>

                                    <h2 className={css.textCenter}>Tema Custom</h2>
                                    <p>
                                        Akun pro punya kesempatan untuk meminta 1 tema custom sesuai keinginan.
                                    </p>

                                </>
                            )
                        }


                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default ProFeatures
